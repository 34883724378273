<template>
	<div>
		<template>
			<p class="text-h6 secondary--text">Delegados UFI</p>

			<div class="ma-3">
				<v-data-table 
					:headers="headers" 
					:items="listaDelegadosUfi.data" 
          :items-per-page="-1">

					<template v-slot:item.email="{ item }">
            {{ item.empleado.usuario.email }}
          </template>

          <template v-slot:item.created_at="{ item }">
						<span v-if="item.empleado.usuario.delegado.length > 0"> 
							{{ moment(item.empleado.usuario.delegado[0]).format("DD/MM/YYYY hh:mm A") }}
						</span>
						<span v-else> 
							Usuario aun no ha sido delegado.
						</span>
					</template>

					<template v-slot:[`item.acciones`]="{ item }">
						<div class="d-flex align-center">
							<v-btn text @click.stop="openAdjunto(item)" v-if="hasDelegado(item)">
								<v-icon>mdi-paperclip</v-icon>
							</v-btn>
						</div>
					</template>
					
					<template v-slot:[`item.switch`]="{ item }">
						<v-switch v-model="cambiarEstado[item.id]" @change="cambiarEstadoDelegadoUfi(item,$event)">
						</v-switch>
						<span :class="{ 'color-activo': hasDelegado(item), 'color-inactivo': !hasDelegado(item) }">
							<span v-if="hasDelegado(item)">Perfil Delegado Ufi</span>
							<span v-else>Perfil UFI</span>
						</span>
					</template>
					
				</v-data-table>
			</div>
		</template>

		<!-- Modal para ingresar el delegado -->
    <v-dialog v-model="dialogAddDelegado" width="800" persistent>
      <v-card class="pa-8">
        <div class="headerIcon">
          <v-icon class="secondary--text" @click="cerrarModal">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <v-col cols="12" md="12">
            <p class="text-h6 font-museo-sans mb-0 secondary--text text-center">
              Agregar un nuevo delegado UFI
            </p>
          </v-col>
          <v-form ref="formBaja" lazy-validation>

            <v-col cols="12">
              <v-file-input 
                v-model="form.adjunto" 
                label="PDF" 
                placeholder="Seleccione un PDF" 
                outlined 
                light
                :error-messages="form.Errors"
                @change="$v.form.adjunto.$touch()" 
                @blur="$v.form.adjunto.$touch()"
                accept="application/pdf" />
            </v-col>

            <!-- Botones de accion -->
            <v-row>
              <v-col cols="6">
                <v-btn color="primary" block @click="cerrarModal">
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block color="secondary" class="white--text text-capitalize" :disabled="botonDesactivado" @click="crearRegistroUfi">
                  Registrar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

		<PdfModal
			:isOpen="modalVisualizacionAdjuntoAbierta" 
			:source-loadable="visualizacionAdjunto" 
			@on-visibility-change="manejarVisibilidadModalVisualizacionAdjunto"
			filename="visualizacion-delegado"
		/>
		<ConfirmationModalComponent 
			:isOpen="modalConfirmacionEliminar" 
			description="¿Desea continuar con la eliminación del registro?"
			@confirm="deleteRegistro" 
			@cancel="cancelarEliminacion" 
			:isLoading="deleteAction.isLoading" 
    />
	</div>
</template>

<script>
import PdfModal from '@/components/PdfModal.vue';
import {
	createLoadable,
	toggleLoadable,
	setLoadableResponse,
} from '@/utils/loadable.js';
import { required } from "vuelidate/lib/validators";
import { Validator } from '@/utils/form-validation.js';
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';

export default {
	name: "indexDelegadosUcp",
	components: { PdfModal, ConfirmationModalComponent },
	validations: {
    form: {
        id_usuario_delegado: { required },
        adjunto: { required },
    },
  },
	data: () => ({
		listaDelegadosUfi: [],
		es_delegado: null,
		dialogAddDelegado: false,
		modalConfirmacionEliminar: false,
		cambiarEstado: false,
		estado: false,
		botonDesactivado: false, 
		form: {
      adjunto: null,
    },
		dataEsDelegado: null,
		dataItem: null,
		deleteAction: createLoadable(null),
		//visualizar Adjunto
		visualizacionAdjunto: createLoadable(null),
		modalVerAdjunto: false,
		modalVisualizacionAdjuntoAbierta: false,
		headers: [
        {
          text: "Correo",
          value: "email",
          align: "center",
          sortable: false,
        },
        {
          text: "Delegado desde",
          value: "created_at",
          align: "center",
          sortable: false,
        },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
        },
        {
          text: "Cambiar estado",
          value: "switch",
          sortable: false,
        },
      ],
	}),
	methods: {
		cerrarModal() {
			this.form.adjunto = null;
			this.dialogAddDelegado = false;
			this.listarDelegadosUfi();
		},
		cancelarEliminacion() {
      this.modalConfirmacionEliminar = false;
      this.listarDelegadosUfi();
    },
		async listarDelegadosUfi() {
			toggleLoadable(this.listaDelegadosUfi);
			const { data } = await this.services.DelegadosUCp.getListaDelegadosUfi();
			this.listaDelegadosUfi = data;
		
			const response = data.data
			this.cambiarEstado = {};
			// Iterar sobre los elementos de data y establecer cambiarEstado para cada ítem
			response.forEach(item => {
					// Verificar si el elemento tiene delegados
					this.$set(this.cambiarEstado, item.id, item.empleado.usuario.delegado.length > 0);
			});
			setLoadableResponse(this.listaDelegadosUfi, data);
		},
		manejarVisibilidadModalVisualizacionAdjunto(visible) {
			this.modalVisualizacionAdjuntoAbierta = visible;
		},
		async openAdjunto(item) {
			this.modalVisualizacionAdjuntoAbierta = true;
			const idDelegado = item.empleado.usuario.id;
			toggleLoadable(this.visualizacionAdjunto);
			const { data } = await this.services.DelegadosUCp.visualizarAdjuntoDelegadoUfi(idDelegado);
			setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
		},
		async cambiarEstadoDelegadoUfi(item, estado) {
			this.dataItem = item;
			this.dataEsDelegado = estado;
			if (estado) {
				this.dialogAddDelegado = true;
			}
			else {
				this.modalConfirmacionEliminar = true;
			}
		},
		async crearRegistroUfi() {
      this.botonDesactivado = true;
			const formData = new FormData();
			formData.append('id_usuario_delegado', this.dataItem.empleado.usuario.id);
			formData.append('adjunto', this.form.adjunto);
			// formData.append('switchEstado', this.dataEsDelegado);

			const { data, status } = await this.services.DelegadosUCp.crearDelegadoUFI(formData);

			if (status === 200) {
				this.temporalAlert({
					message: data.message,
					show: true,
					type: "success",
				});
			}
			else {
				this.temporalAlert({
					show: true,
					message: data.error.message,
					type: "error",
				});
			}
      this.botonDesactivado = false;
			this.cerrarModal();
		},
		async deleteRegistro() {
			const idUsuarioDelegado = this.dataItem.empleado.usuario.id;
      toggleLoadable(this.deleteAction);
      const { data } = await this.services.DelegadosUCp.deleteDelegadoUFI(idUsuarioDelegado);
      setLoadableResponse(this.deleteAction, data, { showAlertOnSuccess: true, skipOnSuccess: true });
      this.modalConfirmacionEliminar = false;
      this.listarDelegadosUfi();
    },
	},
	computed: {
		adjuntoErrors() {
			return new Validator(this.$v.form.adjunto).detect().getResult();
		},
		hasDelegado() {
			return (item) => item.empleado?.usuario?.delegado && item.empleado?.usuario?.delegado.length > 0;
		},

	},
	async created() {
		await this.listarDelegadosUfi();
	}
}
</script>

<style>
.color-activo {
  color: blue; /* Color azul cuando está activo */
}

.color-inactivo {
  color: #7c8188;

}

</style>